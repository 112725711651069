export class Item {
    item: string;
    description: string;
    price: number;
    number: number;
    subtotal: number;

    constructor (item: string, description: string, price: number, number: number,subtotal: number) {
        this.item = item;
        this.description = description;
        this.number = number;
        this.price = price;
        this.subtotal = subtotal;
    }
}