import React from 'react';
import logo from './logo.svg';
import './App.css';
import Faktura from './faktura';

const App: React.FC = () => {
  return (
    <>
    <Faktura></Faktura>
    <div>

        <button className="noprint" onClick={() => window.print()}>PRINT</button>

      </div>

    </>

  )

}

export default App;
