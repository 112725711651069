import * as React from 'react';
import Table from 'react-bootstrap/Table'
import { Item } from './item';

export interface IFakturaProps {
}

export interface IFakturaState {
  orders: Item[],
  order: Item,
  totalPrice: number
}

export default class Faktura extends React.Component<IFakturaProps, IFakturaState> {
  constructor(props: IFakturaProps) {
    super(props);

    this.state = {
      order: {
        item: "",
        description: "",
        price: 0,
        number: 0,
        subtotal: 0
      },
      totalPrice: 0,
      orders: []
    }
  }

  componentDidMount = () => {

  }

  addItem = () => {
    let tempItems = this.state.orders;
    let newItem = new Item(" ", " ", 0, 0, 0);
    tempItems.push(newItem);
    
    this.setState({
      orders: tempItems
    });
  }

  deleteItem = (index: number) => {
    console.log(index)
    let tempItems = this.state.orders;
    const t = tempItems.splice(index, 1);
    this.setState({
      orders: t
    })
  }
  handleChangeinput(event: React.ChangeEvent<HTMLInputElement>, index: number, order: Item) {
    event.persist()
    console.log(event.target.value, index, order);
    switch (event.target.name) {
      case 'price':
        order.price = parseFloat(event.target.value);
        break;

      case 'number':
        order.number = parseInt(event.target.value);
        break;
      default:
        break;
    }
    let tempItems = this.state.orders;
    order.subtotal = parseFloat((order.price * order.number).toFixed(2));
    let total = 0;
    this.state.orders.forEach(order => {
      total = total + order.subtotal;
    });
    tempItems[index] = order;
    this.setState({
      orders: tempItems,
      totalPrice: total
    });

  }

  calcprice = (order: Item, index: number) => {
    order.subtotal = order.price * order.number;
    return (
      order.subtotal
    );
  }

  handleChangeTextarea(event: React.ChangeEvent<HTMLTextAreaElement>, index: number, order: Item) {
    event.persist()
    console.log(event.target.value, index, order);
    switch (event.target.name) {
      case 'item':
        order.item = event.target.value;
        break;
      case 'description':
        order.description = event.target.value;
        break;
    }
    let tempItems = this.state.orders;
    tempItems[index] = order;
    this.setState({
      orders: tempItems
    });

  }

  itemlist = () => {
    return (
      this.state.orders.map((order, index) => {
        return (
          <tr key={index} className="item-row">
            <td className="item-name"><div className="delete-wpr"><textarea name="item" id="" onChange={(event) => this.handleChangeTextarea(event, index, order)} value={order.item}></textarea><a className="delete noprint" onClick={() => this.deleteItem(index)} title="Remove row">X</a></div></td>
            <td className="description"><textarea name="description" onChange={(event) => this.handleChangeTextarea(event, index, order)} value={order.description}></textarea></td>
            <td><input className="cost" type='number' name="price" onChange={(event) => this.handleChangeinput(event, index, order)} value={order.price}></input></td>
            <td><input className="qty" type='number' name="number" onChange={(event) => this.handleChangeinput(event, index, order)} value={order.number}></input></td>
            <td><span className="price">{order.subtotal} kr</span></td>
          </tr>
        );
      })
    );



  }

  public render() {
    return (
      <div id="page-wrap">

        <textarea readOnly id="header" value="FAKTURA"></textarea>

        <div id="identity">

          <textarea placeholder="Firma" id="address"></textarea>

          <div id="logo">

            {/* <div id="logoctr">
                    <a href="javascript:;" id="change-logo" title="Change logo">Change Logo</a>
                    <a href="javascript:;" id="save-logo" title="Save changes">Save</a>
                    |
                    <a href="javascript:;" id="delete-logo" title="Delete logo">Delete Logo</a>
                    <a href="javascript:;" id="cancel-logo" title="Cancel changes">Cancel</a>
                  </div> */}

            {/* <div id="logohelp">
                    <input id="imageloc" type="text" value="" /><br />
                    (max width: 540px, max height: 100px)
                  </div>
                  <img id="image" src="images/logo.png" alt="logo" />*/}
          </div>

        </div>

        <div></div>

        <div id="customer">
          <textarea placeholder='Kunde' id="customer-title"></textarea>

          <Table id="meta">
            <tbody>
              <tr>
                <td className="meta-head">Faktura nummer</td>
                <td><textarea></textarea></td>
              </tr>
              <tr>

                <td className="meta-head">Dato</td>
                <td><textarea id="date"></textarea></td>
              </tr>
              <tr>
                <td className="meta-head">Beløb i alt</td>
                <td><div className="due"></div>{this.state.totalPrice}</td>
              </tr>
            </tbody>
          </Table>

        </div>

        <Table id="items">
          <tbody>
            <tr>
              <th>Enhed</th>
              <th>Beskrivelse</th>
              <th>Pris pr Enhed</th>
              <th>Antal</th>
              <th>Pris</th>
            </tr>

            {this.itemlist()}


            <tr id="hiderow" className="noprint">
              <td colSpan={5}><a id="addrow" onClick={this.addItem} title="Add a row">Tilføj Række</a></td>
            </tr>

           {/*  <tr>
              <td colSpan={2} className="blank"> </td>
              <td colSpan={2} className="total-line">Subtotal</td>
              <td className="total-value"><div id="subtotal"></div></td>
            </tr> */}
            <tr>

              <td colSpan={2} className="blank"> </td>
              <td colSpan={2} className="total-line">Total</td>
              <td className="total-value"><div id="total"></div></td>
            </tr>
            {/* <tr>
              <td colSpan={2} className="blank"> </td>
              <td colSpan={2} className="total-line">Beløb betalt</td>

              <td className="total-value"><textarea id="paid"></textarea></td>
            </tr> */}
            <tr>
              <td colSpan={2} className="blank"> </td>
              <td colSpan={2} className="total-line balance">At betale</td>
              <td className="total-value balance"><div className="due">{this.state.totalPrice} kr</div></td>
            </tr>
          </tbody>
        </Table>

        <div id="terms">
          <h5>Betingelser</h5>
          <textarea readOnly value="Betaling skal falde inden 14 dage, fra dags dato, ellers vil et gebyr på 10% blive pålagt."></textarea>
        </div>

      </div>
    );
  }
}
